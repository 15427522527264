import { FC, memo } from "react";

import {
  AvailableTag as TitleTag,
} from "apps/website/components/base/Text/Text.map";
import Grid from "apps/website/components/layout/Grid/Grid";
import { AvailableTag } from "apps/website/components/base/List/List.map";
import { Gap } from "apps/website/components/layout/Grid/Grid.map";

import IconTextListItem, {
  IIconTextItem,
} from "./IconTextListItem/IconTextListItem";
import { Columns, IconWidth } from "./IconTextListItem/IconTextListItem.map";

export type Flow = "column" | "row";

export interface IIconTextList {
  items: IIconTextItem[];
  itemTitleTag?: TitleTag;
  listType?: AvailableTag;
  gap?: Gap;
  flow?: Flow;
  columns?: Columns;
  iconWidth?: IconWidth;
}

const IconTextList: FC<IIconTextList> = ({ items = [], listType = "ul", gap = "default", columns, iconWidth = "default" }) => (
  <Grid tag={listType} component="IconTextList" gap={gap}>
    { items.map((item) => (
      <IconTextListItem
        key={item.title}
        item={item}
        columns={columns}
        iconWidth={iconWidth}
      />
    )) }
  </Grid>
);

export default memo(IconTextList);
